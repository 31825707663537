
import { defineComponent ,ref ,reactive, toRefs} from 'vue';
import { recordDetails } from '@/api/saleManage/withdraw'
export default defineComponent({
    name:'withdrawDialog',
    setup(){
        const visible = ref<boolean>(false);
        const dataSource = reactive({
            data:[],
            page:1,
            pageSize:0,
            cashData:{},
            applicant:{},
            acceptor:{}
        })
        const columns = [
            {
                title: '名称',
                dataIndex: 'cash_name',
                key: 'cash_name',
            },
            {
                title: '提现金额',
                dataIndex: 'amount_sum',
                key: 'amount_sum',
            },
            {
                title: '二维码',
                children: [
                    {
                        title: '微信',
                        dataIndex: 'er_wx',
                        key: 'er_wx',
                        width: 200,
                    },
                    {
                        title: '支付宝',
                        dataIndex: 'er_zfb',
                        key: 'er_zfb',
                    },
                    {
                        title: '安卓',
                        dataIndex: 'er_az',
                        key: 'er_az',
                    },
                    {
                        title: '苹果',
                        dataIndex: 'er_pg',
                        key: 'er_pg',
                    },
                ]
            },
            {
                title: '自然流量',
                width: 80,
                children:[
                    {
                        title: '安卓',
                        dataIndex: 'zr_az',
                        key: 'zr_az',
                        width: 200,
                    },
                    {
                        title: '苹果',
                        dataIndex: 'zr_pg',
                        key: 'zr_pg',
                    },
                ]
            },
        ];

        const showModal = (val:object) => {
            visible.value = true;
            dataSource.data = []
            recordDetails({page:dataSource.page,id:(val as any).id}).then((res:any)=>{
                
                if(res.code == 200){
                   (dataSource.data as any).push(res.data.models)
                    dataSource.pageSize = res.data.perpage;
                    dataSource.cashData = res.data.agent_team_result;
                    dataSource.applicant = res.data.progress.applicant;
                    dataSource.acceptor = res.data.progress.acceptor;
                    
                    // console.log(dataSource.step);
                    
                }
            })
        };
        const handleOk = (e: MouseEvent) => {
            console.log(e);
            visible.value = false;
        };

        
        return {
            visible,
            columns,
            ...toRefs(dataSource),
            showModal,
            handleOk,
        }
    }
})
