import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from "@/utils/common";

// 提现详单
export const getCashList = (data:object): Promise<AxiosResponse<object>> =>{
    let url = setUrl('/agent-api/api/cash_out_list?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 提现记录 详情
export const recordDetails = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/cash_record_detail?page=${data.page}&id=${data.id}`,
        // data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 提现申请
export const withApplication = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/save_cash_out`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
